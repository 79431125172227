var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"login_container"},[_c('div',{staticClass:"g_w1200"},[_vm._m(1),_c('div',{staticClass:"login_div"},[_c('router-view')],1)])]),_c('div',{staticClass:"footer"},[_c('div',{staticClass:"ft-main ftw ft-index-block"},[_c('div',{staticClass:"footer_container"},[_c('div',{staticClass:"footer_info"},[_c('dl',{staticClass:"footer_info_column"},[_c('router-link',{attrs:{"to":{name:'indexPage'}}},[_c('img',{staticStyle:{"height":"70px","margin-left":"20px"},attrs:{"src":require("./../../../assets/logo1.png"),"alt":""}})])],1),_vm._m(2),_vm._m(3),_c('dl',{staticClass:"footer_info_column"},[_c('router-link',{attrs:{"to":{name:'Useragreement'},"target":"_blank"}},[_c('dt',[_vm._v("用户协议")])])],1),_vm._m(4),_vm._m(5)])])]),_vm._m(6)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hd_header"},[_c('div',{staticClass:"g_w1200 g_div"},[_c('img',{staticClass:"g_logo",attrs:{"src":require("./../../../assets/logo1.png")}}),_c('span',{staticClass:"g_span"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login_img"},[_c('img',{attrs:{"src":"https://register.ccopyright.com.cn/assets/img/user/loginMain-4f97a3d0.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('dl',{staticClass:"footer_info_column"},[_c('a',{attrs:{"href":"mailto:284508440@qq.com"}},[_c('dt',[_vm._v("联系我们")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dl',{staticClass:"footer_info_column"},[_c('a',{attrs:{"href":"https://ihago.com/","target":"_blank"}},[_c('dt',[_vm._v("关于我们")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dl',{staticClass:"footer_info_column"},[_c('a',{attrs:{"href":"mailto:284508440@qq.com"}},[_c('dt',[_vm._v("加入我们")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dl',{staticClass:"footer_info_column"},[_c('a',{attrs:{"href":"mailto:284508440@qq.com"}},[_c('dt',[_vm._v("订制服务")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer_license ftw"},[_c('p',[_vm._v(" Copyright © 2022 陕西重构教育科技有限公司，All Rights Reserved. ")])])
}]

export { render, staticRenderFns }